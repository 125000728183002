/* formStyles.css */
.title {
  margin-bottom: 32px;
}

.row {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.mui-textField {
  width: 48%;
}

.mui-textField .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 2px solid #c4c4c4 !important;
  border-radius: 0 !important;
}
