@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&family=Karla:ital,wght@0,200..800;1,200..800&family=Montserrat:wght@300&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

.home-background {
  position: relative;
  z-index: 1;
  height: 100vh;
  background-image: url("./../../assets/wavy-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.home-background h1 {
  position: relative;
  z-index: 2;
}

.home-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
